export type econsentDetailsModel = {
  createdAt: string | null;
  updatedAt: Date | null;
  appointmentDateTime: string | null;
  hn: string;
  fullName: string | null;
  fullNameEn: string | null;
  fullNameTh: string | null;
  transactionId: string | null;
  templateName: string | null;
  voidedReason: string | null;
  appointmentId: string | null;
  status: string | null;
  email: string | null;
  nationalID: string | null;
  passportNo: string | null;
  birthDate: string | null;
  e_Signature_Link: string | null;
  patientSubmittedAt: string | null;
  witnessSubmittedAt: string | null;
  salesforce_Case_Appointment_Id: string | null;
  salesforce_Case_Patient_Create_Id: string | null;
  salesforce_Case_Registration_Id: string | null;
  requestCaseID: string | null;
  bookingCaseID: string | null;
  cancelledBy: string | null;
  channel: string | null;
  company: string | null;
  createdBy: string | null;
  delegateTo: string | null;
  note: string | null;
};

export type econsentColumnModel = {
  appointmentDateTime: string;
  remainingDays: number;
  hn: string;
  fullName: string;
  status: string;
  transactionId: string;
  templateName: string;
  salesforce_Case_Appointment_Id: string;
  salesforce_Case_Patient_Create_Id: string;
  appointmentId: string;
  email: string;
  nationalID: string;
  passportNo: string;
  birthDate: string;
  voidedReason: string;
  patientSubmittedAt: string;
  witnessSubmittedAt: string;
  channel: string;
  company: string;
  createdBy: string
  delegateTo: string;
  note: string;
};

export type econsentColumnSpecial = {
  e_Signature_Link: 'e_Signature_Link'
};

export enum statusType {
  PENDING_TO_CREATE_TRANSACTION = 0,
  PENDING_PATIENT = 1,
  PENDING_WITNESS = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  DECLINED = 5,
  EXPIRED = 6,
  UNDEFINED = 99
}

export enum channelType {
  NONE = 'None',
  SALEFORCE = 'Saleforce',
  IPS = 'IPS',
  TRAKCARE = 'Trakcare',
  ALL = 'All'
}

export enum companyType {
  NONE = 'None',
  BUMRUNGRAD = 'BH',
  VITALLIFE = 'VTL',
  ESPERANCE = 'AGR',
  ALL = 'All'
}

export interface ApiResponse {
  data: econsentDetailsModel[]
  status: number
}

export interface ApiError {
  message: string
  status: number
}

export type CancellationReasonMaster = {
  reason: string;
  description: string;
  requestDetail: boolean;
}